import axios from "axios";
import Cookies from "js-cookie";

const version = "/v1";
const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API + version,
});

const getUserId = (params) => {
  const req = instance.get(`/uuid`, {
    params,
  });
  return req;
};

const getNewsList = (params) => {
  const req = instance.get(`/news`, {
    params,
  });
  return req;
};
const getNewsDetail = (id) => {
  const req = instance.get(`/news/${id}`);
  return req;
};

const getSurveyQuestion = (params) => {
  const req = instance.get(`/questions`, {
    params,
  });
  return req;
};

const getQuestionCategories = (params) => {
  const req = instance.get(`/question-categories`, {
    params,
  });
  return req;
};

const postRequest = (data) => {
  const req = instance.post(`/requests`, data);
  return req;
};

const postTracking = (data) => {
  const userId = Cookies.get("user_id");
  const req = instance.post(`/trackings`, data, {
    headers: { Authorization: "Bearer " + userId },
  });
  return req;
};

const getAds = (params) => {
  const req = instance.get(`/popup`, {
    params,
  });
  return req;
};

const getSearch = (params) => {
  const req = instance.get(`/search`, {
    params,
  });
  return req;
};

export {
  getUserId,
  getNewsList,
  getNewsDetail,
  getSurveyQuestion,
  getQuestionCategories,
  postRequest,
  postTracking,
  getSearch,
  getAds,
};
